import TabletOrderHeader from "../TabletOrderHeader/TabletOrderHeader";
import ProductsTable from "../ProductsTable/ProductsTable";
import PrintReceipt from "../../Buttons/PrintReciept/PrintReciept";
import { useDispatch, useSelector } from "react-redux";
import { RootState, TypedDispatch } from "../../../redux/store";
import {
  areAllCheckedTrue,
  parserForCollectorFoods,
} from "../../../utils/helpers";
import StartCollectOrder from "../../Buttons/StartCollectOrder/StartCollectOrder";
import { useEffect, useState } from "react";
import {
  collectBegin,
  getAssemblingOrders,
  getCollectorOrder,
  getWaitingOrders,
  patchOrderEnd,
  printReceipt,
} from "../../../redux/Tablet/actions";
import {
  setIdNotification,
  setShowingOrder,
  setReadyShowingOrder,
  setStartedCollect,
  setFirstShowingOrder,
  setWaitingOrders,
  setOpenOrders,
  setActiveTab,
} from "../../../redux/Tablet/TabletSlice";
import TabletNotification from "../TabletNotification/TabletNotification";
import { AxiosResponse } from "axios";
import { TabletOrder } from "../../../utils/constants";

function TabletOrderDetail() {
  const { items, id, date, deliver, ready, status } = useSelector(
    (state: RootState) => state.tablet.showingOrder
  );
  const test = useSelector((state: RootState) => state.tablet.showingOrder);
  const { newOrders, assemblingOrders, waitingOrders, activeTab } = useSelector(
    (state: RootState) => state.tablet
  );
  const [notification, setNotification] = useState(false);
  const dispatch = useDispatch<TypedDispatch>();

  const handlerReady = () => {
    if (areAllCheckedTrue(items)) {
      dispatch(setReadyShowingOrder(true));
    } else {
      dispatch(setReadyShowingOrder(false));
    }
  };

  useEffect(() => {
    if (areAllCheckedTrue(items)) {
      dispatch(setReadyShowingOrder(true));
    } else {
      dispatch(setReadyShowingOrder(false));
    }
  }, [dispatch, items, id]);

  const handlePrintReceipt = async () => {
    dispatch(
      setIdNotification(id !== 0 ? `${deliver ? "D" : "C"}${id}` : "00000")
    );
    try {
      await dispatch(printReceipt({ orderId: id }))
        .unwrap()
        .then(() => {
          setNotification(true);
          dispatch(getAssemblingOrders({ currentPage: 1 }))
            .unwrap()
            .then((response) => {
              firstShowingOrder(response);
            });
          setTimeout(() => {
            setNotification(false);
          }, 4000);
        });
    } catch (error) {
      activeTab === "assembling" && dispatch(setShowingOrder(TabletOrder));
      activeTab === "new" && dispatch(setShowingOrder(newOrders.orders[0]));
    }

    await dispatch(
      getWaitingOrders({ currentPage: waitingOrders.currentPage })
    );
  };
  ////
  const handleBeginCollect = async () => {
    await dispatch(collectBegin({ orderId: id }));
    dispatch(setStartedCollect());
    await dispatch(getCollectorOrder({ currentPage: newOrders.currentPage }));
  };

  const handleCollectorAccept = () => {
    if (waitingOrders.orders.length === 1) {
      dispatch(patchOrderEnd({ id }));
      dispatch(getWaitingOrders({ currentPage: waitingOrders.currentPage }))
        .unwrap()
        .then((response) => {
          response.data.results.length > 0
            ? firstShowingOrder(response)
            : dispatch(setFirstShowingOrder());
        });
    } else {
      dispatch(setWaitingOrders({ states: waitingOrders, id }));
      dispatch(patchOrderEnd({ id }))
        .unwrap()
        .then(() => {
          setNotification(true);
          dispatch(getWaitingOrders({ currentPage: waitingOrders.currentPage }))
            .unwrap()
            .then((response) => {
              firstShowingOrder(response);
              dispatch(setWaitingOrders({ states: waitingOrders, id }));
            });
          setTimeout(() => {
            setNotification(false);
          }, 4000);
        });
      dispatch(getAssemblingOrders({ currentPage: newOrders.currentPage }))
        .unwrap()
        .then((response) => {
          if (response.date.results.length > 0) {
            dispatch(setFirstShowingOrder());
          }
        });
    }
    handlerReady();
  };

  const firstShowingOrder = (response: AxiosResponse) => {
    switch (activeTab) {
      case "new": {
        if (newOrders.orders.length >= 1) {
          activeTabNew();
        } else if (assemblingOrders.orders.length >= 1) {
          activeTabAssembling();
          dispatch(setActiveTab("assembling"));
        } else {
          dispatch(setActiveTab("new"));
          dispatch(setFirstShowingOrder());
        }
        break;
      }
      case "assembling": {
        if (assemblingOrders.orders.length >= 1) {
          activeTabAssembling();

          dispatch(
            setShowingOrder(
              response.data.results.length > 0
                ? parserForCollectorFoods(response.data.results)[0]
                : TabletOrder
            )
          );

          response.data.results.length > 0 &&
            dispatch(
              setOpenOrders({
                activeTab,
                types: activeTab,
                ids: response.data.results[0].id,
              })
            );
        } else if (newOrders.orders.length >= 1) {
          activeTabNew();
          dispatch(setActiveTab("new"));
        } else {
          dispatch(setFirstShowingOrder());
          dispatch(setActiveTab("new"));
        }
        break;
      }
    }
  };

  const activeTabNew = () => {
    dispatch(setShowingOrder(newOrders.orders[0]));
    dispatch(
      setOpenOrders({
        activeTab,
        types: activeTab,
        ids: newOrders.orders[0].id,
      })
    );
  };

  const activeTabAssembling = () => {
    dispatch(setShowingOrder(assemblingOrders.orders[0]));
    dispatch(
      setOpenOrders({
        activeTab,
        types: activeTab,
        ids: assemblingOrders.orders[0].id,
      })
    );
  };

  return (
    <div className="h-full relative">
      <TabletNotification notification={notification} />
      <TabletOrderHeader
        date={date !== "" ? date : "00-00-0000 18:00"}
        title={id !== 0 ? `${deliver ? "D" : "C"}${id}` : "#0000"}
      />
      <ProductsTable
        handleBeginCollect={handleBeginCollect}
        items={items[0] !== undefined && items.length > 0 ? items : []}
      />
      <div className="w-full bg-white flex justify-between items-center px-4 py-1  relative">
        <StartCollectOrder
          handleBeginCollect={handleBeginCollect}
          status={status}
        />
        {status === 12 && (
          <PrintReceipt
            handlePrintReceipt={handleCollectorAccept}
            ready={status === 12 && true}
            text={"Выдать клиенту"}
            goodToGo={true}
          />
        )}
        {status !== 12 && (
          <PrintReceipt
            handlePrintReceipt={handlePrintReceipt}
            ready={
              items[0] !== undefined && items.length > 0
                ? status === 0
                  ? false
                  : ready
                : true
            }
            text={"распечатать чек"}
          />
        )}
      </div>
    </div>
  );
}
export default TabletOrderDetail;
