import cls from "./ProductsTableRow.module.scss";
import { DetailProduct } from "../../../redux/Tablet/types";

function ProductsTableRow({
  image,
  title,
  status,
  quantity,
  started,
  index,
  orderItemId,
  setChecked,
  setStart,
  id,
  changeItems,
}: DetailProduct) {
  const handlerClick = (arg1: boolean, arg2: boolean, str: string) => {
    if (!started) {
      setStart();
    }
    setChecked(arg1, index, orderItemId ? orderItemId : id, arg2);
  };

  const checkType = (str: string | undefined) => {
    switch (str) {
      case "dish":
        return "Блюда";
        break;
      case "drink":
        return "Напиток";
      case "sauce":
        return "Соус";
      case "card":
        return "Картошка";
      default:
        break;
    }
  };

  return (
    // <div className={status ? cls["tableCellDone"] : cls["tableCell"]}>
    <div
      className={`${
        status ? "bg-[#E1FAE1]" : "bg-white"
      } w-full flex py-1 justify-between border-y border-solid border-[#dadada]`}
    >
      <div className="flex items-center max-w-[450px] w-full">
        <img
          alt={"позиция"}
          src={
            image && image[0] !== "/"
              ? image
              : `${process.env.REACT_APP_BASE_URL_IMAGE}${image}`
          }
          className="mx-4 min-w-[50px] min-h-[70px] max-w-[90px] max-h-[70px] lg:max-h-[98px]"
        />
        <div className="w-auto">
          <p className="text-[#575757] text-xl lg:text-2xl font-extrabold">
            {title}
          </p>
          {changeItems !== undefined && changeItems.length > 0 && (
            <div className="font-bold text-sm">
              {changeItems.map((elem) => (
                <div key={id}>
                  <span className="text-[#8E8E8E]">
                    {checkType(elem.food_type)}:
                  </span>
                  <span className="text-[#575757]">{elem.title}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between gap-3 lg:gap-10 max-w-[175px]">
        <div className="w-[130px] flex justify-start items-center">
          <p className="text-[#575757] text-2xl font-extrabold">X{quantity}</p>
        </div>
        <div className="w-[130px] flex justify-center items-center">
          <button
            onClick={() => {
              handlerClick(!status, status, "1");
            }}
            className={
              status
                ? cls["tableCell-buttonChecked"]
                : cls["tableCell-buttonCheck"]
            }
          ></button>
        </div>
      </div>
    </div>
  );
}
export default ProductsTableRow;
