import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RequireAuth = ({ children }: { children: ReactElement }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("collectors") || "{}");

    if (!auth.collector_phone) {
      navigate("/login");
    }
  }, []);

  return children;
};

export default RequireAuth;
