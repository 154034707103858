import axios from "axios";
import { refreshToken } from "./helpers";

export interface RequestFunc {
  url: string;
  body: null | object;
  type: string;
  auth?: boolean;
}

export const requestFunc = async ({ url, body, type, auth }: RequestFunc) => {
  const storedUserString = localStorage.getItem("collectors");
  let user;

  if (storedUserString !== null) {
    user = JSON.parse(storedUserString);
  }

  try {
    return await axios.request({
      method: type,
      baseURL: process.env.REACT_APP_BASE_URL,
      url,
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      data: body,
    });
  } catch (error: any) {
    if (error.response.status === 401) {
      try {
        const res = await refreshToken({ url, axios, user, type });
        if (res.status >= 200 && res.status < 300) {
          return await axios.request({
            method: type,
            baseURL: process.env.REACT_APP_BASE_URL,
            url,
            headers: {
              Authorization: `Bearer ${res.data.access}`,
              cookie: "",
            },
            data: body,
          });
        }
        return res;
      } catch (err: any) {
        console.log("try to 401 ERROR:", err);
        localStorage.clear();
        return err;
      }
    } else {
      return error;
    }
  }
};
