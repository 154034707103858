import cls from "./TabletOrderHeader.module.scss";
import cn from "classnames";
import { ReactComponent as SandWatchIco } from "../../../assets/icon/ico-sand-watch.svg";
import { ReactComponent as WatchArrowsIco } from "../../../assets/icon/ico-watch-arrows.svg";
import { ReactComponent as CloseIco } from "../../../assets/icon/ico-close1.svg";
import { useDispatch } from "react-redux";
import { TypedDispatch } from "../../../redux/store";
import {
  setCloseOrders,
  setFirstShowingOrder,
} from "../../../redux/Tablet/TabletSlice";
import { isToday, subtractMinutes } from "../../../utils/helpers";
import { useEffect, useState, useMemo, useCallback } from "react";

interface TabletOrderHeaderPropType {
  title: string;
  date: string;
}

function TabletOrderHeader({ title, date }: TabletOrderHeaderPropType) {
  const dispatch = useDispatch<TypedDispatch>();
  const [checkDateOrder, setCheckDateOrder] = useState<boolean>(true);
  const [checkTime, setCheckTime] = useState<boolean>(true);
  const [deadline, setDeadline] = useState("");

  const [datePart, timePart] = date.split(" ");
  const [hour, minute] = timePart.split(":");
  const [year, month] = datePart.split("-");

  const orderYear = `${year}.${month}`;
  const orderTime = Number(hour) + 6 === 24 ? "00" : Number(hour) + 6;
  const fixOrderTime = `${orderTime}:${minute}`;

  const newTime = useMemo(
    () => subtractMinutes([String(orderTime), minute], -40),
    [orderTime, minute]
  );

  const handleCloseOrder = useCallback(() => {
    dispatch(setFirstShowingOrder());
    dispatch(setCloseOrders());
  }, [dispatch]);

  const handlerTime = useCallback((bool: boolean) => {
    setCheckTime(bool);
  }, []);

  useEffect(() => {
    setCheckDateOrder(isToday([year, month]));
  }, [year, month]);

  useEffect(() => {
    const dateEnd = new Date();
    dateEnd.setHours(Number(newTime[0]));
    dateEnd.setMinutes(Number(newTime[1]));
    dateEnd.setSeconds(0);
    const intervalId = setInterval(() => {
      const diff = dateEnd.getTime() - new Date().getTime();
      if (diff <= 0) {
        setDeadline("00:00");
        handlerTime(false);
        clearInterval(intervalId);
      } else {
        const dateDiff = new Date(diff);
        const minutesRemaining = dateDiff.getUTCMinutes();
        const secondsRemaining = dateDiff.getUTCSeconds();
        const formattedTime = `${minutesRemaining
          .toString()
          .padStart(2, "0")}:${secondsRemaining.toString().padStart(2, "0")}`;
        setDeadline(formattedTime);
        handlerTime(true);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [newTime, checkTime, handlerTime]);

  return (
    <div className="w-full h-[157px] p-4 flex items-start justify-between border-b-[1px] border-[#ecedde] border-solid">
      <div className="flex flex-col justify-between gap-[12px] items-start">
        <h1
          className={`${
            checkDateOrder
              ? checkTime
                ? "text-[#00881D]"
                : "text-[#b93e3e]"
              : "text-[#b93e3e]"
          } leading-10 text-[52px] font-extrabold`}
        >
          {title}
        </h1>
        <div className="flex gap-4 items-center justify-center text-4xl text-[#575757] font-extrabold">
          <div>
            {checkDateOrder ? (
              <div className="flex items-center gap-[10px]">
                <span className="flex items-center text-[30px] lg:text-4xl">
                  <WatchArrowsIco className="mt-1 w-8 h-8" />
                  {fixOrderTime}
                </span>
                <span className="flex items-center text-[30px] lg:text-4xl">
                  <SandWatchIco className="mt-1 w-8 h-8" />
                  {deadline}
                </span>
              </div>
            ) : (
              <span className="flex items-center text-[30px] lg:text-4xl">
                <SandWatchIco className="w-8 h-8 mt-1" />
                {`${orderYear}.`}
              </span>
            )}
          </div>
        </div>
      </div>
      <button
        onClick={handleCloseOrder}
        className="mt-1 z-[2] w-[50px] h-[50px] border-0 cursor-pointer rounded-lg bg-[#f5f5f5] flex items-center justify-center"
      >
        <CloseIco className="w-7 h-7" />
      </button>
    </div>
  );
}

export default TabletOrderHeader;
