import { createAsyncThunk } from "@reduxjs/toolkit";
import { POST, LOGIN_COLLECTOR, LOGOUT_COLLECTOR } from "../../utils/constants";
import { LoginParams } from "../Tablet/types";
import { requestFunc } from "../../utils/mainFunc";

export const collectorLogout = createAsyncThunk(
  "tablet/logout",
  async (params: string, thunkApi) => {
    const reqParams = {
      type: POST,
      url: `${process.env.REACT_APP_BASE_URL}${LOGOUT_COLLECTOR}`,
      body: {
        phone_number: params,
      },
      params: null,
      auth: true,
    };
    try {
      return await requestFunc(reqParams);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const logIn = createAsyncThunk(
  "tablet/logIn",
  async (params: LoginParams, thunkApi) => {
    const reqParams = {
      type: POST,
      url: `${process.env.REACT_APP_BASE_URL}${LOGIN_COLLECTOR}`,
      body: {
        phone_number: params.phone_number,
        password: params.password,
      },
      params: null,
      auth: true,
    };
    try {
      return await requestFunc(reqParams);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
