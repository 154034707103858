import cls from "./TabletHeaderTabs.module.scss";
import { ReactComponent as FireIco } from "../../../assets/icon/ico-fire.svg";
import { ReactComponent as WatchIco } from "../../../assets/icon/ico-watch.svg";
import { ReactComponent as ActiveWatchIco } from "../../../assets/icon/ico-watch-active.svg";
import { ReactComponent as IcoSearch } from "../../../assets/icon/ico-search.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState, TypedDispatch } from "../../../redux/store";
import { setActiveTab } from "../../../redux/Tablet/TabletSlice";
import { useEffect } from "react";
import { TabletTabs } from "../../types";

function TabletHeaderTabs({
  waiting,
  newOrder,
  assembling,
  handleSearchOpen,
}: TabletTabs) {
  const dispatch = useDispatch<TypedDispatch>();

  useEffect(() => {}, [newOrder, waiting]);
  const { activeTab } = useSelector((state: RootState) => state.tablet);
  const handleClickTab = (value: string) => {
    dispatch(setActiveTab(value));
  };

  return (
    <div className={cls.tabs}>
      <div className={cls["TabletSearch"]}>
        <button
          className={cls["TabletSearch__searchBtn"]}
          onClick={() => handleSearchOpen()}
        >
          <IcoSearch className={cls["TabletSearch__searchBtn-ico"]} />
        </button>
      </div>
      <button
        className={activeTab === "new" ? cls.active : cls.button}
        onClick={() => handleClickTab("new")}
      >
        Новые ({newOrder})
      </button>
      <button
        className={activeTab === "assembling" ? cls.active : cls.button}
        onClick={() => handleClickTab("assembling")}
      >
        {activeTab === "assembling" ? (
          <ActiveWatchIco className={cls.watchIcoActive} />
        ) : (
          <WatchIco className={cls.watchIco} />
        )}{" "}
        Собираются ({assembling})
      </button>
      <button
        className={activeTab === "waiting" ? cls.active : cls.button}
        onClick={() => handleClickTab("waiting")}
      >
        <FireIco
          className={
            activeTab === "waiting" ? cls.activeFireIcon : cls.fireIcon
          }
        />{" "}
        Ожидают выдачи ({waiting})
      </button>
    </div>
  );
}
export default TabletHeaderTabs;
