import { useState } from "react";
import Image from "../../../assets/img-error-popup.png";
import Popup from "../Popup";

const PopupError = () => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
    localStorage.clear();
  };

  return (
    <Popup open={open} setClose={handleClose}>
      <div className="bg-[#f9eb1d] w-[700px] flex flex-col items-center p-8">
        <img src={Image} alt="error" className="w-56" />
        <div className="grid gap-8">
          <p className="text-[#575757] text-[40px] font-extrabold leading-[110%] max-w-[300px] text-center m-0">
            ой, что-то пошло не так
          </p>
          <button
            onClick={() => handleClose()}
            className="cursor-pointer rounded-2xl bg-[#00881D] py-4 px-7 text-[18px] leading-[110%] text-[#FEFEFB] transition-all ease-linear hover:bg-[#43C23C]"
          >
            Попробовать заново
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default PopupError;
