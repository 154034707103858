import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userSliceInitState } from "./types";
import { collectorLogout, logIn } from "./action";

const initialState: userSliceInitState = {
  login: false,
  authMessage: "",
  logoutOpen: false,
  loading: false,
  userAuth: false,
  errorRequest: false,
};

export const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setAuthMessage: (
      state: userSliceInitState,
      action: PayloadAction<string>
    ) => {
      state.authMessage = action.payload;
    },
    setLogin: (state: userSliceInitState, action: PayloadAction<boolean>) => {
      state.login = action.payload;
    },
    setErrorRequest: (
      state: userSliceInitState,
      action: PayloadAction<boolean>
    ) => {
      state.errorRequest = action.payload;
    },
    setLogoutOpen: (
      state: userSliceInitState,
      action: PayloadAction<boolean>
    ) => {
      state.logoutOpen = action.payload;
    },
    setUserAuth: (
      state: userSliceInitState,
      action: PayloadAction<boolean>
    ) => {
      state.userAuth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logIn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logIn.fulfilled, (state, action: any) => {
      state.login = true;
      state.loading = false;
      state.authMessage = action.payload.data.message;
      const collectors = {
        collector_phone: action.payload.data.user_phone_number,
        token: action.payload.data.access_token,
        rToken: action.payload.data.refresh_token,
        address: action.payload.data.address,
      };
      localStorage.setItem("collectors", JSON.stringify(collectors));
    });
    builder.addCase(logIn.rejected, (state, action: any) => {
      state.loading = false;
      state.authMessage =
        action.payload.response.data.phone_number ||
        action.payload.response.data.password;
    });
    builder.addCase(collectorLogout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(collectorLogout.fulfilled, (state) => {
      state.loading = false;
      localStorage.removeItem("collectors");
    });
    builder.addCase(collectorLogout.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setAuthMessage,
  setLogin,
  setLogoutOpen,
  setUserAuth,
  setErrorRequest,
} = UserSlice.actions;
export default UserSlice.reducer;
